import React, { useState } from "react";

import { Container, Row } from "react-bootstrap";
import "chartjs-plugin-datalabels";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import Title from "../../../components/title";

import { PermissionsPage } from "../../../util/permissionsPage";
import { Accordion, AccordionDetails, AccordionSummary, Box, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { LoadingTable } from "../../../components/Loading/LoadingTable";
import Select from "react-select";
import { useRadarVendas } from "./useRadarVendas";
import { defaultThemes } from "react-data-table-component";
import { FiltroPeriodo } from "../../../components/FiltroPeriodo/FiltroPeriodo";
import { ButtonFilter } from "../../../components/DatePikerFilter/styles";
import { a11yProps } from "../../../util/a11yProps";
import { ExpandMore } from "@mui/icons-material";
import { TabSoldDataCards } from "./components/TabSoldDataCards";
import { TabSoldDataChart } from "./components/TabSoldDataChart";
import { TabNumberOfSalesCards } from "./components/TabNumberOfSalesDataCards";
import { TabNumberOfSalesChart } from "./components/TabNumberOfSalesDataChart";
import { TabCashbackCards } from "./components/TabCashbackDataCards";
import { TabCashbackChart } from "./components/TabCashbackDataChart";

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? " whitesmoke" : "white",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: " #eb0058",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#FFF",
    fontWeight: "bold",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#ff0d6c",
      color: "white",
    },
  }),
};

const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};

function RadarDeVendas(props) {

  const {
    isLoading,
    basesDisponiveis,
    lojasDisponiveis,
    defaultValueBase,
    lojasSelecionadas,
    totalSoldData,
    periodoInputInicioAux,
    periodoInputFimAux,
    totalNumberOfSalesData,
    cashbackData,
    setPeriodoInputInicioAux,
    setPeriodoInputFimAux,
    filtrarBases,
    handleFiltroLoja,
    handleFilter
  } = useRadarVendas()

  console.log({ cashbackData });

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="container-app">
        {PermissionsPage("radar-de-vendas") && (
          <Container>
            <div className="container-conteudo">
              <Box ml={2}>
                <Title title="Radar de vendas/cliente" />
              </Box>

              <Stack gap={2} direction="row" mx={2}>
                <Box component={Paper} variant="outlined" px={1} pb={1}>
                  <FiltroPeriodo
                    periodoInputInicioAux={periodoInputInicioAux}
                    periodoInputFimAux={periodoInputFimAux}
                    setPeriodoInputInicioAux={setPeriodoInputInicioAux}
                    setPeriodoInputFimAux={setPeriodoInputFimAux}
                  />
                  <Stack gap={1}>
                    <ButtonFilter
                      type="button"
                      onClick={() => handleFilter()}
                      disabled={isLoading}>
                      Filtrar
                    </ButtonFilter>
                  </Stack>
                </Box>
                <Box width="100%">
                  <Box pb={1}>
                    <Typography
                      textAlign={"initial"}
                      fontWeight={"bold"}
                      component={"span"}>
                      Base
                    </Typography>
                    <Select
                      options={basesDisponiveis.map((item) => ({
                        label: item.base,
                        value: item.codBase,
                      }))}
                      onChange={(value) => filtrarBases(value.value)}
                      placeholder="Selecione uma base"
                      styles={colourStyles}
                      isDisabled={isLoading}
                      value={{ label: defaultValueBase?.base, value: defaultValueBase?.codBase }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      textAlign={"initial"}
                      fontWeight={"bold"}
                      component={"span"}>
                      Lojas
                    </Typography>
                    <Select
                      options={lojasDisponiveis.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      isMulti
                      onChange={(values) => handleFiltroLoja(values.map(item => item.value))}
                      placeholder="Selecione uma loja"
                      styles={colourStyles}
                      isDisabled={isLoading}
                      value={lojasSelecionadas}
                    />
                    <Typography variant="caption" component={"span"}>
                      *Atenção: Quando nenhuma loja estiver selecionada, todas as
                      lojas serão retornadas.*
                    </Typography>
                  </Box>
                </Box>
              </Stack>
              {isLoading ? (
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"90vh"}
                >
                  <LoadingTable isLoading={isLoading} />
                </Box>
              ) : (
                <Container className="mt-2">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      VALOR VENDIDO
                    </AccordionSummary>
                    <AccordionDetails>
                      <Row className="wrapper">
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            sx={{
                              "& .MuiTabs-flexContainer": {
                                width: "100%",
                                justifyContent: "center",
                              },
                            }}
                            aria-label="basic tabs example"
                          >
                            <Tab label="Cards" {...a11yProps(0)} />
                            <Tab label="Gráfico" {...a11yProps(1)} />
                          </Tabs>
                        </Box>

                        <TabSoldDataCards data={totalSoldData} value={value} />
                        <TabSoldDataChart data={totalSoldData} value={value} />
                      </Row>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      NÚMERO DE COMPRAS
                    </AccordionSummary>
                    <AccordionDetails>
                      <Row className="wrapper">
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            sx={{
                              "& .MuiTabs-flexContainer": {
                                width: "100%",
                                justifyContent: "center",
                              },
                            }}
                            aria-label="basic tabs example"
                          >
                            <Tab label="Cards" {...a11yProps(0)} />
                            <Tab label="Gráfico" {...a11yProps(1)} />
                          </Tabs>
                        </Box>

                        <TabNumberOfSalesCards data={totalNumberOfSalesData} value={value} />
                        <TabNumberOfSalesChart data={totalNumberOfSalesData} value={value} />
                      </Row>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      CASHBACK
                    </AccordionSummary>
                    <AccordionDetails>
                      <Row className="wrapper">
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            sx={{
                              "& .MuiTabs-flexContainer": {
                                width: "100%",
                                justifyContent: "center",
                              },
                            }}
                            aria-label="basic tabs example"
                          >
                            <Tab label="Cards" {...a11yProps(0)} />
                            <Tab label="Gráfico" {...a11yProps(1)} />
                          </Tabs>
                        </Box>

                        <TabCashbackCards data={cashbackData} value={value} />
                        <TabCashbackChart data={cashbackData} value={value} />
                      </Row>
                    </AccordionDetails>
                  </Accordion>
                  {/*<Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      CONDIÇÃO DE PAGAMENTO
                    </AccordionSummary>
                    <AccordionDetails>
                      <Row className="wrapper">
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            sx={{
                              "& .MuiTabs-flexContainer": {
                                width: "100%",
                                justifyContent: "center",
                              },
                            }}
                            aria-label="basic tabs example"
                          >
                            <Tab label="Cards" {...a11yProps(0)} />
                            <Tab label="Gráfico" {...a11yProps(1)} />
                          </Tabs>
                        </Box>

                        <CustomTabPanel value={value} index={0} style={{ padding: '16px' }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                p={1}
                                alignItems={"flex-end"}
                                justifyContent={"space-between"}
                                component={Paper}
                                elevation={1}
                                variant="outlined"
                              >
                                <Typography
                                  variant="h6"
                                  component={"span"}
                                  fontWeight={"normal"}
                                >
                                  Pix
                                </Typography>
                                <Box
                                  display={"flex"}
                                  flexDirection={"row"}
                                  justifyContent={"space-between"}
                                  width={"100%"}
                                >
                                  <GiReceiveMoney fontSize="2rem" color={pink[300]} />
                                  <Typography component={"h5"} variant="h6">
                                    {100}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                p={1}
                                alignItems="flex-end"
                                justifyContent="space-between"
                                component={Paper}
                                elevation={1}
                                variant="outlined"
                              >
                                <Typography
                                  variant="h6"
                                  component="span"
                                  fontWeight="normal"
                                >
                                  BABY+ CONVENIO
                                </Typography>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <GiReceiveMoney fontSize="2rem" color={pink[300]} />
                                  <Typography component="h5" variant="h6">
                                    {40}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                p={1}
                                alignItems="flex-end"
                                justifyContent="space-between"
                                component={Paper}
                                elevation={1}
                                variant="outlined"
                              >
                                <Typography
                                  variant="h6"
                                  component="span"
                                  fontWeight="normal"
                                >
                                  BABY+ 30/100
                                </Typography>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <GiReceiveMoney fontSize="2rem" color={pink[300]} />
                                  <Typography component={"h5"} variant="h6">
                                    {60}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                p={1}
                                alignItems="flex-end"
                                justifyContent="space-between"
                                component={Paper}
                                elevation={1}
                                variant="outlined"
                              >
                                <Typography
                                  variant="h6"
                                  component="span"
                                  fontWeight="normal"
                                >
                                  BABY+ GESTANTE
                                </Typography>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <GiReceiveMoney fontSize="2rem" color={pink[300]} />
                                  <Typography component={"h5"} variant="h6">
                                    {60}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                p={1}
                                alignItems="flex-end"
                                justifyContent="space-between"
                                component={Paper}
                                elevation={1}
                                variant="outlined"
                              >
                                <Typography
                                  variant="h6"
                                  component="span"
                                  fontWeight="normal"
                                >
                                  B+ PRIME VENDIDO
                                </Typography>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <GiReceiveMoney fontSize="2rem" color={pink[300]} />
                                  <Typography component={"h5"} variant="h6">
                                    {60}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                p={1}
                                alignItems="flex-end"
                                justifyContent="space-between"
                                component={Paper}
                                elevation={1}
                                variant="outlined"
                              >
                                <Typography
                                  variant="h6"
                                  component="span"
                                  fontWeight="normal"
                                >
                                  B+ PRIME CREDITO
                                </Typography>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <GiReceiveMoney fontSize="2rem" color={pink[300]} />
                                  <Typography component={"h5"} variant="h6">
                                    {60}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                p={1}
                                alignItems="flex-end"
                                justifyContent="space-between"
                                component={Paper}
                                elevation={1}
                                variant="outlined"
                              >
                                <Typography
                                  variant="h6"
                                  component="span"
                                  fontWeight="normal"
                                >
                                  B+ PRIME RESGATE
                                </Typography>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <GiReceiveMoney fontSize="2rem" color={pink[300]} />
                                  <Typography component={"h5"} variant="h6">
                                    {60}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                          </Grid>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                          <Box
                            sx={{
                              display: ["grid", "grid", "flex"],
                              padding: '32px',
                              width: "100%",
                            }}
                          >

                            <Pie
                              data={{
                                labels: ["Pix", "BABY+ CONVENIO", "BABY+ 30/100", "BABY+ GESTANTE", "B+ PRIME VENDIDO", "B+ PRIME CREDITO", "B+ PRIME RESGATE"],
                                datasets: [
                                  {
                                    label: "# of Votes",
                                    data: [
                                      100,
                                      40,
                                      60,
                                      60,
                                      60,
                                      60,
                                      60,
                                    ],
                                    backgroundColor: [
                                      "rgba(255, 206, 86, 0.2)",
                                      "rgba(75, 192, 192, 0.2)",
                                      "rgba(153, 102, 255, 0.2)",
                                      "rgba(251, 159, 64, 0.2)",
                                      "rgba(205, 159, 64, 0.2)",
                                      "rgba(055, 159, 64, 0.2)",
                                      "rgba(155, 159, 64, 0.2)",
                                    ],
                                    borderColor: [
                                      "rgba(255, 206, 86, 1)",
                                      "rgba(75, 192, 192, 1)",
                                      "rgba(153, 102, 255, 1)",
                                      "rgba(255, 159, 64, 1)",
                                      "rgba(205, 159, 64, 1)",
                                      "rgba(150, 159, 64, 1)",
                                      "rgba(000, 159, 64, 1)",
                                    ],
                                    borderWidth: 1,
                                  },
                                ],
                              }}
                              options={{
                                plugins: {
                                  datalabels: {
                                    formatter: (value, ctx) => {
                                      const dataset = ctx.chart.data.datasets[ctx.datasetIndex];
                                      const total = dataset.data.reduce(
                                        (acc, currentValue) => acc + currentValue,
                                        0
                                      );
                                      const percentage = ((value / total) * 100).toFixed(2) + "%";
                                      return `${value} | ${percentage}`;
                                    },
                                    color: "gray", // Cor dos rótulos
                                    font: {
                                      weight: "bold",
                                    },
                                  },
                                },
                                tooltips: {
                                  callbacks: {
                                    label: (tooltipItem, data) => {
                                      const dataset = data.datasets[tooltipItem.datasetIndex];
                                      const currentValue = dataset.data[tooltipItem.index];
                                      return (
                                        data.labels[tooltipItem.index] +
                                        ": " +
                                        currentValue
                                      );
                                    },
                                  },
                                },
                                legend: {
                                  display: true,
                                  position: "left",
                                },
                              }}
                            />
                          </Box>
                        </CustomTabPanel>
                      </Row>
                    </AccordionDetails>
                  </Accordion> */}
                </Container>
              )}
            </div>
          </Container >
        )
        }
      </div >
    </>
  );
}

export default RadarDeVendas;
